import React from "react";
import "./App.css";
import Navbar from "./Navbar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Contact from "./Contact";
import Name from "./Name";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/contact" component={Contact} />
          <Route path="/" component={Name} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
