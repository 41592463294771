import React from "react";
import "./Navbar.css";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";

function Navbar() {
    return (
        <div className="navbar">
            <div className="navbar__container">
                <div className="navbar__buttons">
                    <Link to="/" className="navbar__link">
                        <div className="navbar__button">
                            <HomeIcon fontSize={"large"}/>
                        </div>
                    </Link>
                    <Link to="/contact" className="navbar__link">
                        <div className="navbar__button">Contact Me</div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
