import React from "react";
import "./Name.css";

function Name() {
  return (
    <div className="name">
      <div className="name__title">Sushmita Yadav</div>
      <div className="name__subTitle">Digital marketer & Data analyst</div>
    </div>
  );
}

export default Name;
