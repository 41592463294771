import React from "react";
import "./Contact.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";

function About() {
    return (
        <div className="contact">
            <div className="contact__page">
                <div className="contact__container">
                    <div className="contact__socialMediaContainer">
                        <a
                            href="https://www.linkedin.com/in/ysushmita1/"
                            target={"_blank"}
                            rel={"noreferrer"}
                            className="contact__socialLink"
                        >
                            <div className="contact__buttonTitle">
                                <LinkedInIcon/><h3 className="contact__linkTitle">LinkedIn</h3>
                            </div>
                        </a>
                        <a
                            href="mailto:ysushmita235@gmail.com"
                            rel={"noreferrer"}
                            className="contact__socialLink"
                        >
                            <div className="contact__buttonTitle">
                                <EmailIcon/>
                                <h3 className="contact__linkTitle">Email</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
